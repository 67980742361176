
@font-face {
  font-family: SourceSansProLight;
  src: url(./components/shared/assets/fonts/SourceSansPro-Light.ttf);
}
 @font-face {
  font-family: SourceSansProRegular;
  src: url(./components/shared/assets/fonts/SourceSansPro-Regular.ttf);
} 
@font-face {
  font-family: Oswald;
  src: url(./components/shared/assets/fonts/Oswald-Bold.ttf);
} 
@font-face {
  font-family: OswaldR;
  src: url(./components/shared/assets/fonts/Oswald-Regular.ttf);
} 
@font-face {
  font-family: TacticSandRegular;
  src: url(./components/shared/assets/fonts/TacticSansExd-Reg.ttf);
}
@font-face {
  font-family: TacticSandBold;
  src: url(./components/shared/assets/fonts/TacticSansExd-Bld.ttf);
} 
@font-face {
  font-family: "TacticSansExdBld";
  src: url(./components/shared/assets/fonts/TacticSansExd-Bld.woff2) format('woff2');
}
@font-face {
  font-family: "TacticSansExdReg";
  src: url(./components/shared/assets/fonts/TacticSansExd-Reg.ttf) format('woff2');
}

body {
  margin: 0;
  background-color: white;
  font-family: "SourceSansProLight", "HarmonyOSSansLight","","TacticSansExdBld";
}

code {
  font-family: SourceSansProLight,source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace,"SourceSansProLight", "TacticSansExdReg","TacticSansExdBld";
}

p,div,a,button{
  font-family: "SourceSansProLight"!important;
  text-decoration: none!important;
  font-weight: bold,;
}
h1,h2,h3,h5,h4{
  font-family: "SourceSansProLight","TacticSansExdReg","TacticSansExdBld";
}

.footerT {
  color: rgb(255, 255, 255)!important;
  margin-top: 21px!important;
  margin-bottom: 10px!important;
  font-size: 25px!important;

  font-weight: lighter!important;
}
.footerText {
  color: rgb(255, 255, 255)!important;
  margin: 10px 0px!important;

  font-size: 18px!important;

}

.Oswald
{
  margin: 10px 0px!important;
  color: black!important;
  font-size: 25px!important;

}
.OswaldT
{
  margin: 10px 0px!important;
  color: rgb(255, 255, 255)!important;
  font-size: 18px!important;

}